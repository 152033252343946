@import "styles/variables";
@import "styles/sizes";

$breakpoint: 450px;

.buttonsContainer {
  display: grid;
  justify-content: left;
  grid-auto-flow: column;
  grid-gap: 30px;


  @include viewport-breakpoint($breakpoint) {
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
  }

  .button {
    min-width: 120px;
  }
}

.table {
  tr, td, th {
    border: 1px solid $chakra-gray-200;
  }
}
