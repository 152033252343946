@import 'styles/variables';
@import 'styles/sizes';

.navBar {
  font-size: 14px;
  min-width: 240px;
  margin: 22px 50px 0 0;

  .categoryList {
    list-style-type: none;
    margin: 10px 0;
  }

  .lessonsList {
    list-style-type: none;
    margin-left: 30px;
    margin-top: 6px;
  }

  .lessonItem {
    margin-bottom: 4px;
    padding: 2px 8px;

    &:hover {
      background-color: $sidebar-hover-item;
    }
  }

  .currentLessonItem {
    background-color: $sidebar-active-item;
  }
}

.lessonLink {
  display: flex;
  font-size: 16px;
}
.lessonLinkTitle {
  display: flex;
  font-size: 20px;
  font-weight: 500;
}
