.layout {
  --header-height: 56px;

  display: flex;
  flex-direction: column;
}

.content {
  padding-top: var(--header-height);
  //margin-bottom: 48px;
}

.contentWithBanner {
  padding-top: 0;
}