@import 'styles/variables';
@import 'styles/sizes';

$breakpoint: 520px;

.bannerWrapper {
  --banner-height: 44px;

  color: white;
  height: var(--banner-height);
  margin-top: var(--header-height);
  padding: 6px 16px;
  background-color: $chakra-red-300;

  @include viewport-breakpoint($breakpoint) {
    --banner-height: 68px;
    flex-wrap: wrap;
  }

  .bannerButton {
    height: 30px;
    min-width: 140px;
    border: 1px solid white;

    @include viewport-breakpoint($breakpoint) {
      width: 240px;
    }
  }
}

.successfulBanner {
  background-color: $chakra-green-300;
}