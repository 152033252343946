@import 'styles/variables';
@import 'styles/sizes';

.cardWrapper {
  width: 100%;
  margin-top: 2px;
  margin-bottom: 18px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.4s ease 0s;
  text-decoration: none !important;

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  }

  @include phone {
    margin-top: 0;
    margin-bottom: 10px;
    padding: 4px 10px 18px
  }
}

.cardBody {
  margin-left: 20px;

  @include phone {
    margin-left: 6px;
  }
}
