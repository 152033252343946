@import 'styles/sizes';
@import 'styles/variables';

$breakpoint: 860px;

.title {
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
}

.about {
  justify-content: space-between;
  flex-wrap: wrap;

  @include viewport-breakpoint($breakpoint) {
    justify-content: center;
  }
}

.aboutContainer {
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include viewport-breakpoint($breakpoint) {
    padding-top: 16px;
    max-width: 100%;
  }
}

.facebook {
  color: $facebook-blue;
}

.google {
  &:nth-child(3n + 1) {
    color: $google-blue;
  }

  &:nth-child(4n + 2) {
    color: $google-red;
  }

  &:nth-child(3) {
    color: $google-orange;
  }

  &:nth-child(5) {
    color: $google-green;
  }
}

.aboutParagraph {
  font-family: var(--chakra-fonts-KumbhSans);
  line-height: 30px;
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }
}

.aboutParagraphLink {
  text-decoration: underline;
}

.link {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
