@import './variables';

/**
 * .container {
 *   background-color: green;
 *
 *   @include big-phone {
 *     background-color: blue;
 *   }
 *
 *   @include phone-portrait {
 *     background-color: red;
 *   }
 * }
 *
 * The order of mixins usage is important.
 * We should use them descending (desktop -> laptop -> tablet -> phone-orientated -> phone).
 */
@mixin phone {
  @media only screen and (max-width: $phone-breakpoint) {
    @content;
  }
}

//@mixin only-phone-orientated {
//  @media only screen and (min-width: #{$phone-breakpoint + 1}) and (max-width: $tablet-breakpoint) {
//    @content;
//  }
//}
//
@mixin phone-orientated {
  @media only screen and (max-width: $tablet-breakpoint) {
    @content;
  }
}

//@mixin only-tablet {
//  @media only screen and (min-width: #{$tablet-breakpoint + 1}) and (max-width: $laptop-breakpoint) {
//    @content;
//  }
//}

@mixin tablet {
  @media only screen and (max-width: $laptop-breakpoint) {
    @content;
  }
}

//@mixin only-laptop {
//  @media only screen and (min-width: #{$laptop-breakpoint + 1}) and (max-width: $desktop-breakpoint) {
//    @content;
//  }
//}

@mixin laptop {
  @media only screen and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: #{$desktop-breakpoint + 1}) {
    @content;
  }
}

@mixin viewport-breakpoint($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}
