.questionTabs {
  --question-tab-width: unset;
  --question-tab-height: 100%;
  --submissions-container-margin-top: 30px;

  display: flex;
  height: var(--question-tab-height);
  flex-direction: column;
  width: var(--question-tab-width);
}

.questionTabsFullWidth {
  width: 100%;
}

.submissionsContainer {
  margin-top: var(--submissions-container-margin-top);
}

.questionMobileView {
  --question-tab-width: 100%;
  --submissions-container-margin-top: 0;
  --question-tab-height: unset;
}