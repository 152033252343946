@import 'styles/variables';
@import 'styles/sizes';

$breakpoint: 680px;

.nonHighlightedCard {
  margin-top: 50px;

  @include viewport-breakpoint($breakpoint) {
    margin: 0;
  }
}

.cardBody {
  flex-direction: column;
  padding: 30px 40px;
  width: 100%;

  @include tablet {
    padding: 16px 26px;
  }
  @include phone {
    padding: 10px 16px;
  }
}

.optionsList {
  list-style-type: "✓";
  width:100%;
}

.link {
  text-decoration: underline;
  display: inline-block;
}
