@import 'styles/variables';
@import 'styles/sizes';

$breakpoint: 740px;

.referralWrapper {
  grid-column: 1 / 3;
  padding: 18px;
  border: 1px solid $chakra-gray-300;
  border-radius: 20px;
  flex-direction: column;

  @include tablet {
    grid-column: unset;
  }

  .subSection {
    padding-bottom: 20px;
  }

  .referralLinkWrapper {
    margin-top: 22px;
    justify-content: flex-start;
    grid-template-columns: auto minmax(auto, 250px);
    grid-gap: 14px;

    @include viewport-breakpoint($breakpoint) {
      max-width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 10px;
    }
  }

  .link {
    text-decoration: underline;
  }
}
