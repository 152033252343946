@import 'styles/variables';

.container {
  padding: 24px;
  border: 1px solid $light-gray-2;
  border-radius: 4px;
  font-style: italic;
}

.commonLogo {
  width: 100%;
  max-width: 80%;
  height: 100px;
  margin: 0 auto 16px;
  object-fit: contain;
}

.quote {
  margin-bottom: 16px;
  white-space: pre-line;
}

.authorPosition {
  margin-left: 20px;
}
