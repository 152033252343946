/**********************************************************/
/*********************** Z-Index **************************/
/**********************************************************/

$z-index-shadow: -1;
$z-index-layer: 5;
$z-index-header: 10;
$z-index-notification: 100;
$z-index-alert: 1000;

/**********************************************************/
/*********************** Typography ***********************/
/**********************************************************/

$font-regular: 'Montserrat-regular', sans-serif;

/**********************************************************/
/********************* Offsets Basic **********************/
/**********************************************************/

// Offset = padding or margin
// Margin could be only right and bottom. Top or left should be parent's padding
$offset-basic: 8px;

/* colors */

// New redesign colors
// NOTE: in cases where we do not use Chakra UI components we keep some of the variables here
$black-1: #0a0a0a;
$black-2: #4d4d4d;
$black-3: #666666;

$gray-1: #fcfcfc;
$gray-2: #f0f0f0;
$gray-3: #e5e5e5;
$gray-4: #dadada;

$blue-1: #daeaf3;

// Old colors
$white: #fff;
$white-transparent: #ffffff00;
$dark-gray: #263238;
$light-gray: #f7f9fa;
$light-gray-2: #c6cbd1;
$light-gray-3: #dfe2e5;
$light-gray-4: #c4d5d5;
$light-gray-5: #a0aec0;
$light-gray-6: #f7f7f9;
$light-blue: #77a0c0;
$light-red: #fff2f2;
$chakra-blue-100: #bee3f8;
$chakra-blue-300: #63b3ed;
$chakra-blue-400: #4299e1;
$chakra-blue-500: #3182ce;
$chakra-red-300: #fc8181;
$chakra-red-400: #f56565;
$chakra-red-500: #e53e3e;
$chakra-green-300: #68d391;
$chakra-gray-200: #e2e8f0;
$chakra-gray-100: #edf2f7;
$chakra-gray-200: #e2e8f0;
$chakra-gray-300: #cbd5e0;
$chakra-gray-400: #a0aec0;
$chakra-gray-600: #4a5568;
$chakra-gray-800: #1a202c;
$link-blue: #1983C4;
$link-purple: #800080;
$link-red: #ff0000;
$primary-red: #ed1c24;
$sidebar-active-item: #ffcaca;
$sidebar-hover-item: #ffd5d5;
$facebook-blue: #1778f2;
$google-blue: #4285f4;
$google-red: #ea4335;
$google-orange: #fbbc05;
$google-green: #34a853;

$desktop-breakpoint: 1200px;
$laptop-breakpoint: 960px;
$tablet-breakpoint: 768px;
$phone-breakpoint: 390px;
