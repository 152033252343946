@import 'styles/sizes';

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    --item-percentage: 33.33%;
    --item-margin: 8px;

    flex: 0 0 calc(var(--item-percentage) - var(--item-margin) * 2);
    margin: var(--item-margin);

    @include tablet {
      --item-percentage: 50%;
    }

    @include phone-orientated {
      flex: 0;
    }
  }

  @include phone-orientated {
    flex-direction: column;
    flex-wrap: unset;
    justify-content: unset;
  }
}

.testimonial {
  max-width: 380px;
  height: 100%;
  margin: 0 auto;
}
