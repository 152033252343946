@import 'styles/variables';

.reactMarkdown {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  overflow-x: auto;

  * {
    color: var(--chakra-colors-black-500);
  }

  a:link {
    color: $link-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  a:visited {
    color: $link-purple;
  }

  a:active {
    color: $link-red;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h1 {
    font-size: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 24px;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 16px;

    code {
      font-size: 18px;
    }
  }

  h4 {
    font-size: 16px;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  h5 {
    font-size: 14px;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  h6 {
    font-size: 10px;
    margin-top: 38px;
    margin-bottom: 38px;
  }

  p {
    margin-bottom: 16px;
  }

  img {
    margin-bottom: 16px;
  }

  ul,
  ol {
    padding-left: 40px;
    margin-bottom: 10px;
  }

  pre,
  code,
  kbd,
  samp {
    font-size: 14px;
  }

  pre {
    div {
      border-radius: 6px;
      background: var(--chakra-colors-gray-200) !important;
    }
  }

  code {
    font-family: var(--chakra-fonts-code), serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    line-height: 24px;
    background: var(--chakra-colors-gray-200);
    border-radius: 4px;
    padding: 1px 4px;

    span {
      font-size: 14px;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 300px;
  }

  table tr th,
  table tr td {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-right: 1px solid var(--chakra-colors-gray-300);
    border-bottom: 1px solid var(--chakra-colors-gray-300);
  }

  table tr th:first-child,
  table tr td:first-child {
    border-left: 1px solid var(--chakra-colors-gray-300);
  }

  table tr th {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
    border-top: solid 1px var(--chakra-colors-gray-300);
  }

  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: 4px;
  }

  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }
}
