@import 'styles/variables';

.title {
  font-weight: 700;
  color: $primary-red;
  text-align: center;
  margin: 26px 0 16px;
}

.subTitle {
  font-family: var(--chakra-fonts-KumbhSans);
  text-align: center;
  margin-bottom: 30px;
}

.subTitleLink {
  text-decoration: underline;
}
