@import 'styles/variables';

.icon {
  width: 20px;
  height: 20px;
  color: var(--chakra-colors-blue-400);
}

.button {
  margin: 0.25rem 0.5rem 0.25rem 0;

  &:last-child {
    margin-right: 0;
  }
}
