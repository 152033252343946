@import "styles/variables";
@import "styles/sizes";

$breakpoint: 500px;

.form {
  display: flex;
  flex-direction: column;

  .submitButton {
    width: 120px;
    align-self: center;
    margin-top: 16px;

    @include viewport-breakpoint($breakpoint) {
      width: 100%;
    }
  }

  .input {
    font-size: 14px;
    font-family: sans-serif;
    min-height: 50px;
    color: black;
  }
}

.stripeInput {
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-md);
  font-family: var(--chakra-fonts-body);
  line-height: var(--chakra-lineHeights-base);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  min-height: 50px;
  padding: 16px 14px;

  &:hover {
    border-color: $chakra-gray-300;
  }
}

.stripeInputInvalid {
  border-color: $primary-red !important;
  box-shadow: 0 0 0 1px $primary-red;
}

.stripeInputFocus {
  z-index: 1;
  border-color: $chakra-blue-300 !important;
  box-shadow: 0 0 0 1px $chakra-blue-300;
}
